import * as Realm from 'realm-web'

import { app, mongo } from "./client"

const {
  BSON: { ObjectId },
} = Realm
const SERVICE = import.meta.env.VITE_MONGODB_SERVICE
const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'comments'
const STUDENT_COLLECTION = 'students'

export async function getAll() {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { owner_id: app.currentUser.id }
    )
}

export async function getStudentClasses() {
  return await mongo()
    .db(DATABASE)
    .collection(STUDENT_COLLECTION)
    .aggregate([
      { $unwind: '$classes' },
      { $project: { 'classes.owner_id': 0 } },
      { $project: {
          _id: 0,
          owner_id: 1,
          student: {
            _id: '$_id',
            name: '$name',
            pronouns: '$pronouns',
          },
          class: '$classes',
          text: '',
        }
      },
    ])
}

export async function updateMany(docs) {
  const payload = {
    service: SERVICE,
    db: DATABASE,
    collection: COLLECTION,
    docs: docs,
  }
  app.currentUser.functions.bulkWrite(payload)
}