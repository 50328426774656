<template>
  <nav class="sidebar has-background-white-bis">
    <div class="sidebar-header">
      <img id="brik-wordmark" src="/brik_wordmark_red.png">
    </div>

    <div class="sidebar-body">
      <RouterLink class="sidebar-item" :to="{ name: 'home' }">
        Overview
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'students' }">
        Students
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'classes' }">
        Classes
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'expectations' }">
        Expectations
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'reports' }">
        Reports
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'report_cards' }">
        Report Cards
      </RouterLink>
    </div>
    
    <div class="sidebar-footer">
      <a class="sidebar-item" target="_blank" href="mailto:support@hillenvale.consulting">
        Help
      </a>
      <RouterLink class="sidebar-item" :to="{ name: 'generalReferralSettings' }">
        Invite
      </RouterLink>
      <RouterLink class="sidebar-item" :to="{ name: 'settings' }">
        Settings
      </RouterLink>
      <a class="sidebar-item"  @click="logout">
        Logout
      </a>
    </div>
  </nav>
</template>

<script>
import { RouterLink } from 'vue-router'
import { mapActions } from 'pinia'

import { useAuthUserStore } from '@/stores/authUser'

export default {
  name: 'SideBar',
  methods: {
    ...mapActions(useAuthUserStore, ['logoutUser']),
    async logout() {
      await this.logoutUser()
      .then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  },
}
</script>

<style scoped>
.sidebar {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.sidebar-header {
  flex: 0 1 auto;
  padding: 1em 1.5em 1.4em;
}

.sidebar-body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  width: 100%;
  padding-bottom: 1em;
}

.sidebar-item {
  padding: .25em 1.5em;
}

a {
  color: hsl(0, 0%, 21%);
}

a:hover {
  background-color: hsl(0, 0%, 96%);
}

#brik-wordmark {
  max-width: 100px;
}
</style>