import axios from 'axios'

import { app, mongo } from "./client"

const DATABASE = import.meta.env.VITE_MONGODB_DB
const REGIONS_COLLECTION = 'regions'
const COLLECTION = 'expectations'
const MONGODB_APP_ID = import.meta.env.VITE_MONGODB_APP_ID

export async function getAll(region, teachables) {
  const query = teachables.length === 0 
    ? { region: region } 
    : { region: region, $or: teachables }

  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(query)
}

export async function getAssessmentExpectations(assessment) {
  return mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find({ _id: { $in: assessment.expectations } })
}

export async function getRegions() {
  return await mongo()
    .db(DATABASE)
    .collection(REGIONS_COLLECTION)
    .find({})
}

export async function getRegionsAnonymously() {
  const options = {
    method: 'GET',
    url: `https://us-central1.gcp.data.mongodb-api.com/app/${MONGODB_APP_ID}/endpoint/regions`,
  }
  const response = await axios.request(options)
  return response?.data.result
}

export async function getAllTeachables(region) {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .aggregate([{ 
      $match: { 
        'region': region
      }
    }, {
      $group: {
        _id: '$grade',
        subjects: { $addToSet: '$subject' }
      }
    }, {
      $project: {
        subjects: {
          $sortArray: {
            input: '$subjects',
            sortBy: 1
          }
        }
      }
    }, {
      $sort: { _id: 1 }
    }
  ])
}