import { defineStore } from 'pinia'

import { Template } from '@/db'

export const useTemplateStore = defineStore('template', {
  state: () => {
    return {
      items: [],
    }
  },
  actions: {
    async getAll() {
      await Template.getAll()
        .then((response) => {
          this.items = response
        })
    },
    async insertOne(doc) {
      await Template.insertOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async updateOne(doc) {
      await Template.updateOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async deleteOne(doc) {
      await Template.deleteOne(doc)
        .then(() => {
          this.getAll()
      })
    },
  },
})
