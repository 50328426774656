import * as Realm from 'realm-web'

import { app, mongo } from "./client"

const {
  BSON: { ObjectId },
} = Realm
const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'assessments'

export async function getAll() {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { owner_id: app.currentUser.id }
    )
}

export async function getOne(doc) {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .findOne({ _id: new ObjectId(doc._id) })
}

export async function insertOne(doc) {
  doc.owner_id = app.currentUser.id
  doc.expectations = []
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .insertOne(doc)
}

export async function updateOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: doc._id },
      { $set: doc }
    )
}

export async function addStudentsToAssessments(students, _class) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateMany(
      { 'class._id': _class._id },
      { $addToSet: { students: { $each: students } } }
    )
}

export async function removeAssessmentsFromStudent(student, _class) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateMany(
      { 'class._id': _class._id },
      { $pull: { students: { _id: student._id } } }
    )
}

export async function deleteOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .deleteOne({ _id: doc._id })
}

export async function deleteClassAssessments(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .deleteMany({ 'class._id': doc._id })
}

export async function saveStudentGrades(newDoc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: newDoc._id },
      { $set: { students: newDoc.students } }
    )
}

export async function addClassToAssessment(assessment, _class) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: assessment._id },
      { $set: { class: _class } }
    )
}

export async function addExpectationsToAssessment(assessment, expectations) {
  const newExpectationGrades = expectations.map((item) => {
    return {
      _id: item._id,
      expectation: item.expectation,
      grade: '',
    }
  })
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: assessment._id },
      { 
        $addToSet: { 
          expectations: { $each: expectations },
          'students.$[].expectationGrades': { $each: newExpectationGrades }
        },
      }
    )
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .findOne({ _id: assessment._id })
}

export async function removeExpectationsFromAssessment(assessment, expectation) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: assessment._id },
      { 
        $pull: { 
          expectations: { _id: expectation._id },
          'students.$[].expectationGrades': { _id: expectation._id }
        }
      }
    )
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .findOne({ _id: assessment._id })
}

export async function getAssessmentsForStudent(student) {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .aggregate([{ 
      $match: { 
        'students._id': student._id
      } 
    }, {
      // Put the student into a top-level property.
      $addFields: { 
        student: {
          $arrayElemAt: [
            {
              $filter: {
                input: '$students',
                as: 'student',
                cond: { $eq: [ '$$student._id', student._id ] }
              }
            }, 0
          ]
        },
      }
    }, {
      // Create a temp object to hold the grades the student received.
      $addFields: {
        gradeObject: {
          $arrayElemAt: [
            {
              $filter: {
                input: '$gradingScheme.grades',
                as: 'grade',
                cond: { $eq: [ '$$grade.name', '$student.grade' ] }
              }
            }, 0
          ]
        },
      }
    }, {
      // Put the grade info into top-level properties.
      $addFields: {
        grade: '$gradeObject.name',
        percentage: '$gradeObject.percentage'
      }
    }, {
      $project: {
        name: 1,
        grade: 1,
        class: 1,
        percentage: 1,
        'expectations._id': 1,
        // Get the grade percentage for each expectation grade.
        'expectationGrades': {
          $map: {
            input: '$student.expectationGrades',
            in: {
              _id: '$$this._id',
              grade: {
                $cond: {
                  if: { $eq: [ '$$this.grade', '' ] },
                  then: '',
                  else: { 
                    $arrayElemAt: [
                      {
                        $filter: {
                          input: '$gradingScheme.grades',
                          as: 'grade',
                          cond: { $eq: [ '$$grade.name', '$$this.grade' ] }
                        }
                      }, 0
                    ]
                  }
                }
              }
            }
          }
        }
      }
    }
  ])
}

export async function getAssessmentsForClass(_class) {
  const result = await mongo()
  .db(DATABASE)
  .collection(COLLECTION)
  .find(
    { 'class._id': _class._id },
    {
      projection: {
        gradingScheme: 1,
        students: 1,
        expectations: 1,
      }
    }
  )
  return result
}

export async function updateAssessmentGradingScheme(scheme) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateMany(
      { 'gradingScheme._id': scheme._id },
      { $set: { gradingScheme: scheme } }
    )
}