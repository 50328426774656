import { SHORT_MONTHS } from './constants'

export function formatDate(type, date) {
  let text

  switch (type) {
    case 'short':
      const month = SHORT_MONTHS[date.getMonth()]
      text = month + ' ' + date.getDate() + ', ' + date.getFullYear()
      break
    default:
      text = date.toString()
  }
  return text
}