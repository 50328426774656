import { defineStore } from 'pinia'
import _ from 'lodash'

import { Assessment } from '@/db'
import { useExpectationStore } from './expectation'
import { useReportStore } from './report'

export const useAssessmentStore = defineStore('assessment', {
  state: () => {
    return {
      items: [],
      studentAssessments: [],
      classAssessments: [],
    }
  },
  actions: {
    async getAll() {
      await Assessment.getAll()
        .then((response) => {
          this.items = response
        })
    },
    async getOne(doc) {
      await Assessment.getOne(doc)
        .then((response) => {
          this.currentItem = response
        })
    },
    async insertOne(doc) {
      await Assessment.insertOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async updateOne(doc) {
      await Assessment.updateOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async deleteOne(doc) {
      await Assessment.deleteOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async saveStudentGrades(assessment) {
      await Assessment.saveStudentGrades(assessment)
        .then(() => {
          this.getAll()
        })
    },
    async addClassToAssessment(assessment, _class) {
      await Assessment.addClassToAssessment(assessment, _class)
        .then(() => {
          this.getAll()
        })
    },
    async addExpectationsToAssessment(assessment) {
      const expectationStore = useExpectationStore()
      if (assessment != null && expectationStore.selectedItems.length) {
        return await Assessment.addExpectationsToAssessment(assessment, expectationStore.selectedItems)
      }
    },
    async removeExpectationsFromAssessment(assessment, expectation) {
      if (assessment != null) {
        return await Assessment.removeExpectationsFromAssessment(assessment, expectation)
      }
    },
    async addStudentsToAssessments(_students, _class) {
      // For each assessment, for each expectation, make sure each student has a 
      // corresponding expectation grade.
      this.items = this.items.map(assessment => {
        if (!_.isEqual(assessment.class._id, _class._id)) {
          return assessment
        }

        var students = _.cloneDeep(_students)
        students.forEach(_student => {
          var student = _.pick(_student, ['_id', 'name'])
          student.expectationGrades = []
          assessment.expectations.forEach(expectation => {
            student.expectationGrades.push({
              _id: expectation._id,
              expectation: expectation.expectation,
              grade: '',
            })
          })
          assessment.students.push(student)
        })
        Assessment.updateOne(assessment)
        return assessment
      })
    },
    getAssessmentsWithFilters(filters) {
      var assessments = this.items.filter(assessment => {
        return filters.every(filter => {
          let result

          // If we need to filter an array, the path should be the path to the array.
          // The value should be an object we can use with _.find(). Example: { _id: 'abc' }
          if (filter.type === 'array') {
            const array = _.get(assessment, filter.path)
            result = _.find(array, filter.searchTerm)

          // Otherwise, the filter key should be a path we can use with _.get().
          } else if (filter.type === 'object') {
            result = _.isEqual(_.get(assessment, filter.path), filter.searchTerm)
          }
          return result
        })
      })
      return assessments
    },
    async getAssessmentsForStudent(student) {
        await Assessment.getAssessmentsForStudent(student)
          .then((response) => {
            this.studentAssessments = response
          })
    },
    async getAssessmentsForClass(_class) {
      await Assessment.getAssessmentsForClass(_class)
        .then((response) => {
          this.classAssessments = response
        })
    },
    async getAverageGradeForStudentByClass(student, _class) {      
      // Create student ID and class ID filters.
      const filters = [
        {
          path: 'students',
          type: 'array',
          searchTerm: { _id: student._id }
        },
        {
          path: 'class._id',
          type: 'object',
          searchTerm: _class._id
        }
      ]
      
      // Find assessments matching those filters.
      const assessments = this.getAssessmentsWithFilters(filters)
      
      // Calculate the student's average. Undefined means we're using the overall grade,
      // not an expectation-level grade.
      const reportStore = useReportStore()
      return Math.round(reportStore.getAvgFromOverallGrades(assessments, student._id, undefined))
    },
    async updateAssessmentGradingScheme(scheme) {
      await Assessment.updateAssessmentGradingScheme(scheme)
        .then(() => {
          this.getAll()
        })
    },
    async replaceStudentGrades(gradingSchemeId, removedGrades) {
      // Create replacement grade lookup.
      var replacementGrades = {}
      for (const removedGrade of removedGrades) {
        replacementGrades[removedGrade.name] = removedGrade.replacementGrade?.name || ''
      }

      // Only update assessments that use this grading scheme.
      var assessments = this.items.filter((item) => {
        return _.isEqual(item.gradingScheme._id, gradingSchemeId)
      })

      for (var assessment of assessments) {
        var isChanged = false
        for (var student of assessment.students) {
          if (Object.keys(replacementGrades).includes(student.grade)) {
            isChanged = true
            student.grade = replacementGrades[student.grade]
          }
          for (var expectationGrade of student.expectationGrades) {
            if (Object.keys(replacementGrades).includes(expectationGrade.grade)) {
              isChanged = true
              expectationGrade.grade = replacementGrades[expectationGrade.grade]
            }
          }
        }
        if (isChanged) {
          const updateDoc = {
            _id: assessment._id,
            students: assessment.students
          }
          await Assessment.updateOne(updateDoc)
        }
      }
      await this.getAll()
    },
  },
})
