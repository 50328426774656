import { app, mongo } from "./client"

const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'classes'
const STUDENT_COLLECTION = 'students'
const ASSESSMENT_COLLECTION = 'assessments'

export async function getAll() {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { owner_id: app.currentUser.id }
    )
}

export async function insertOne(doc) {
  doc.owner_id = app.currentUser.id
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .insertOne(doc)
}

export async function updateOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: doc._id },
      { $set: doc }
    )
}

export async function deleteOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .deleteOne({ _id: doc._id })
}

export async function getStudentsInClass(doc) {
  return await mongo()
    .db(DATABASE)
    .collection(STUDENT_COLLECTION)
    .aggregate([
      { '$match': { 'classes._id': { '$eq': doc._id } } },
      { '$project': { name: 1 } },
    ])
}

export async function getAssessmentsInClass(doc) {
  return await mongo()
    .db(DATABASE)
    .collection(ASSESSMENT_COLLECTION)
    .aggregate([
      { '$match': { 'class._id': { '$eq': doc._id } } },
      { '$project': { 
        name: 1, 
        expectations: 1, 
        gradingScheme: 1, 
        students: 1,
        date: 1,
      } },
    ])
}