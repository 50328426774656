import { defineStore } from 'pinia'

import { Assessment, Class, Student } from '@/db'
import { formatDate } from '@/utils/date'

export const useClassStore = defineStore('class', {
  state: () => {
    return {
      items: [],
    }
  },
  actions: {
    async getAll() {
      await Class.getAll()
        .then((response) => {
          this.items = response
        })
    },
    async insertOne(doc) {
      await Class.insertOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async updateOne(doc) {
      await Class.updateOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async deleteOne(doc) {
      await Assessment.deleteClassAssessments(doc)
      await Student.removeClassFromAllStudents(doc)
        .then(async () => {
          await Class.deleteOne(doc)
          .then(() => {
            this.getAll()
          })
        })
    },
    async getStudentsInClass(_class) {
      return await Class.getStudentsInClass(_class)
    },
    async getAssessmentsInClass(_class) {
      let classes = await Class.getAssessmentsInClass(_class)
      return classes.map(_class => {
        _class.date = _class.date ? formatDate('short', _class.date) : _class.date
        return _class
      })
    },
  },
})
