<template>
  <div v-if="appDataIsLoaded || !this.isLoggedIn" class="columns is-gapless">
    <SideBar v-if="showSidebar" class="column is-2 px-0"/>
    <div class="column">
      <RouterView style="height: 100%"/>
    </div>
  </div>

  <SpinnerIcon v-else></SpinnerIcon>
</template>

<script>
import { RouterView } from 'vue-router'
import { mapState, mapActions } from 'pinia'

import SideBar from './components/Sidebar.vue'
import SpinnerIcon from './components/SpinnerIcon.vue'
import { useAuthUserStore } from '@/stores/authUser'

export default {
  name: 'App',
  components: { SideBar, SpinnerIcon },
  data() {
    return {
      appIsLoaded: false,
    }
  },
  computed: {
    ...mapState(useAuthUserStore, [
      'isLoggedIn',
      'userData',
      'appDataIsLoaded',
      'setupComplete',
    ]),
    showSidebar() {
      return (
        this.isLoggedIn 
        && this.setupComplete 
        && ['active', 'trialing'].includes(this.userData.billing.subscription.status) 
        && this.$route.name !== 'setup'
      )
    },
  },
  methods: {
    ...mapActions(useAuthUserStore, [
      'getUserData', 
      'getLocalLoggedInUsers', 
      'checkIfUserIsLoggedIn',
      'loadAppData',
    ]),
  },
  async mounted() {
    this.getLocalLoggedInUsers()
    this.checkIfUserIsLoggedIn()
    if (this.isLoggedIn) {
      this.loadAppData()
    }
  },
}
</script>

<style scoped>
.columns {
  height: 100vh;
}
</style>