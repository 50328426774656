import { defineStore } from 'pinia'

import { Assessment, Student } from '@/db'
import { useAssessmentStore } from './assessment'


export const useStudentStore = defineStore('student', {
  state: () => {
    return {
      items: [],
    }
  },
  actions: {
    async getAll() {
      await Student.getAll()
        .then((response) => {
          this.items = response
        })
    },
    async insertMany(students) {
      await Student.insertMany(students)
        .then(() => {
          this.getAll()
        })
    },
    async updateOne(doc) {
      await Student.updateOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async deleteOne(doc) {
      await Student.deleteOne(doc)
        .then(() => {
          this.getAll()
        })
    },
    async addClassToStudents(students, _class) {
      const assessmentStore = useAssessmentStore()
      await Student.addClassToStudents(students, _class)
      await assessmentStore.addStudentsToAssessments(students, _class)
      this.getAll()
    },
    async removeClassFromStudent(student, _class) {
      await Student.removeClassFromStudent(student, _class)
        .then(() => {
          Assessment.removeAssessmentsFromStudent(student, _class)
          this.getAll()
        })
    },
  },
})
