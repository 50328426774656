import { defineStore } from 'pinia'
import _ from 'lodash'

import { Auth, Client } from '@/db'
import { useClassStore } from '@/stores/class'
import { useStudentStore } from '@/stores/student'
import { useExpectationStore } from '@/stores/expectation'
import { useAssessmentStore } from '@/stores/assessment'
import { useCommentStore } from '@/stores/comment'
import { useTemplateStore } from '@/stores/template'
import { useReportStore } from '@/stores/report'

export const MAX_TEACHABLES = 20

export const useAuthUserStore = defineStore('authUser', {
  state: () => {
    return {
      userData: {},
      isLoggedIn: false,
      appDataIsLoaded: false,
    }
  },
  getters: {
    hasRegionSelected(state) {
      return !_.isNull(state.userData.regionId)
    },
    hasTeachablesSelected(state) {
      return this.numSelectedSubjects > 0
    },
    setupComplete() {
      return this.hasRegionSelected && this.hasTeachablesSelected
    },
    numSelectedSubjects(state) {
      const grades = Object.keys(state.userData.teachables)
      let total = 0
      grades.forEach(grade => {
        total += state.userData.teachables[grade].length
      })
      return total
    },
    overSubjectLimitBy() {
      return this.numSelectedSubjects - MAX_TEACHABLES
    },
  },
  actions: {
    checkIfUserIsLoggedIn() {
      this.isLoggedIn = Client.app.currentUser?.isLoggedIn || false
    },
    getLocalLoggedInUsers() {
      const authenticatedUsers = Object.values(Client.app.allUsers).filter(
        (user) => user.isLoggedIn
      )
      if (authenticatedUsers.length > 0) {
        const user = authenticatedUsers[0];
        Client.app.switchUser(user)
      }
    },
    async loginUser(email, password) {
      return Auth.loginUser(email, password)
        .then(() => {
          this.getUserData()
        })
    },
    async logoutUser() {
      await Auth.logoutUser()
        .then(() => {
          this.userData = {}
          this.isLoggedIn = false
          this.appDataIsLoaded = false
        })
    },
    async getUserData() {
      return await Auth.getUserData()
        .then((response) => {
          this.userData = response
        })
    },
    async updateUserData() {
      await Auth.updateUserData(this.userData)
        .then(async () => {
          await this.getUserData()
        })
    },
    hasAccessTo(feature) {
      return (
        this.userData.billing.subscription.product.metadata[feature] === 'true'
        || this.userData.billing.customer.metadata[feature] === 'true'
      )
    },
    requestBetaAccess() {
      this.userData.betaAccessRequest = 'new'
      this.updateUserData()
    },
    async loadAppData() {
      const classStore = useClassStore()
      const studentStore = useStudentStore()
      const expectationStore = useExpectationStore()
      const assessmentStore = useAssessmentStore()
      const commentStore = useCommentStore()
      const templateStore = useTemplateStore()
      const reportStore = useReportStore()

      await this.getUserData()
      await classStore.getAll()
      await studentStore.getAll()
      await expectationStore.getAll()
      await assessmentStore.getAll()
      await commentStore.getAll()
      await templateStore.getAll()
      await reportStore.getAll()
      this.appDataIsLoaded = true
    }
  },
})
