<template>
  <span id="spinner" class="icon is-large">
    <i class="fa-2x fa-solid fa-spinner fa-spin-pulse"></i>
  </span>
</template>

<script>
export default {
  name: 'SpinnerIcon',
}
</script>

<style scoped>
#spinner {
  text-align: center;
  width: 100vw;
  height: 100vh;
}
</style>