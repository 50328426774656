import { createRouter, createWebHistory } from 'vue-router'
import _ from 'lodash'

import { useAuthUserStore } from '@/stores/authUser'


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'app' },
    },
    {
      path: '/app',
      name: 'app',
      redirect: { name: 'home' },
      children: [
        {
          path: 'home',
          name: 'home',
          component: () => import('../views/HomeView.vue'),
        },
        {
          path: 'classes/:id',
          name: 'class',
          component: () => import ('../views/class/ClassView.vue'),
        },
        {
          path: 'classes',
          name: 'classes',
          component: () => import('../views/class/ClassesView.vue'),
        },
        {
          path: 'assessments/:id',
          name: 'assessment',
          component: () => import('../views/assessment/AssessmentView.vue'),
        },
        {
          path: 'students/:id',
          name: 'student',
          component: () => import ('../views/student/StudentView.vue'),
        },
        {
          path: 'students',
          name: 'students',
          component: () => import('../views/student/StudentsView.vue'),
        },
        {
          path: 'expectations',
          name: 'expectations',
          component: () => import('../views/expectation/ExpectationsView.vue'),
        },
        {
          path: 'expectations/:id',
          name: 'expectation',
          component: () => import('../views/expectation/ExpectationView.vue'),
        },
        {
          path: 'report-cards',
          name: 'report_cards',
          component: () => import('../views/reportCard/ReportCardsView.vue'),
        },
        {
          path: 'reports/:id',
          name: 'report',
          component: () => import('../views/report/ReportView.vue'),
        },
        {
          path: 'reports',
          name: 'reports',
          component: () => import('../views/report/ReportsView.vue'),
        },
        {
          path: 'settings',
          name: 'settings',
          component: () => import('../views/settings/SettingsView.vue'),
          redirect: { path: '/app/settings/app/general' },
          children: [
            {
              path: 'app/general',
              name: 'generalAppSettings',
              component: () => import('../views/settings/app/GeneralView.vue'),
            },
            {
              path: 'app/tags',
              name: 'tagSettings',
              component: () => import('../views/settings/app/TagView.vue'),
            },
            {
              path: 'app/grading-schemes',
              name: 'gradingSchemeSettings',
              component: () => import('../views/settings/app/GradingSchemesView.vue'),
            },
            {
              path: 'account/general',
              name: 'generalAccountSettings',
              component: () => import('../views/settings/account/GeneralView.vue'),
            },
            {
              path: 'referral/general',
              name: 'generalReferralSettings',
              component: () => import('../views/settings/referral/GeneralView.vue'),
            },
          ],
        },
        {
          path: 'setup',
          name: 'setup',
          component: () => import('../views/SetupView.vue'),
        },
        {
          path: 'billing',
          name: 'billing',
          redirect: { name: 'checkout' },
          children: [
            {
              path: 'checkout',
              name: 'checkout',
              component: () => import('../views/billing/CheckoutView.vue'),
            },
            {
              path: 'return',
              name: 'return',
              component: () => import('../views/billing/ReturnView.vue'),
            },
          ]
        }
      ],
      meta: {
        requiresAuth: true,
      },
    },  
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/auth/RegisterView.vue'),
    },
    {
      path: '/confirm-email',
      name: 'confirmEmail',
      component: () => import('../views/auth/ConfirmEmailView.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/auth/LoginView.vue'),
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: () => import('../views/auth/ForgotPasswordView.vue'),
    },
    {
      path: '/password-reset',
      name: 'passwordReset',
      component: () => import('../views/auth/ResetPasswordView.vue'),
    },
  ]
})

router.beforeEach(async (to, from) => {
  const authUserStore = useAuthUserStore()
  authUserStore.getLocalLoggedInUsers()
  authUserStore.checkIfUserIsLoggedIn()

  if (authUserStore.isLoggedIn && _.isEmpty(authUserStore.userData)) {
    authUserStore.getUserData()
  }

  // User is not logged in. Can't go to app.
  if (to.meta.requiresAuth && !authUserStore.isLoggedIn) {
    return { name: 'login' }

  // User is already logged in. Can't go to login page.
  } else if (to.name === 'login' && authUserStore.isLoggedIn) {
    return { name: 'app' }

  // User does not have a subscription. Can't go to app.
  } else if (
    to.meta.requiresAuth
    && authUserStore.isLoggedIn
    && to.path.search('billing') === -1
    && !['active', 'trialing'].includes(authUserStore.userData.billing?.subscription.status)
  ) {
    return { name: 'checkout' }
  
  // User has not set up their account. Go to setup page.
  } else if (
    to.name !== 'setup'
    && !_.isEmpty(authUserStore.userData)
    && !authUserStore.setupComplete
  ) {
    return { name: 'setup' }
  }
})

router.beforeResolve(async (to, from) => {
  const authUserStore = useAuthUserStore()

  if (to.name === 'setup' && authUserStore.setupComplete) {
    return { name: 'app' }
  }
})

export default router
