import { defineStore } from 'pinia'
import _ from 'lodash'

import { Report } from '@/db'

export const useReportStore = defineStore('report', {
  state: () => {
    return {
      items: [],
    }
  },
  actions: {
    async getAll() {
      await Report.getAll()
        .then((response) => {
          this.items = response
        })
    },
    async getOne(id) {
      let item = _.find(this.items, { _id: id })
      if (!item) {
        item = await Report.getOne(id)
        this.items.push(item)
      }
      return item
    },
    async updateOne(doc) {
      await Report.updateOne(doc)
      var item = _.find(this.items, { _id: doc._id })
      if (item) {
        item = doc
      } else {
        this.items.push(doc)
      }
    },
    async deleteOne(doc) {
      await Report.deleteOne(doc)
      this.items = this.items.filter(item => !_.isEqual(item._id, doc._id))
    },
    getAvgFromOverallGrades(assessments, studentId, expectationId) {
      // studentId and expectationId can optionally be set to filter the assessments
      // by a particular student or expectation.
      var percentages = []

      assessments.map(assessment => {
        const assessmentPercentages = assessment.students.map(student => {
          if (studentId === undefined || _.isEqual(studentId, student._id)) {
            if (expectationId === undefined) {
              // Use the student's overall assessment grade.
              return this.convertGradeToPercentage(student.grade, assessment.gradingScheme) 
            } else {
              // Use the student's expectation grade.
              const expectationGrade = _.find(student.expectationGrades, { _id: expectationId })
              return this.convertGradeToPercentage(expectationGrade?.grade, assessment.gradingScheme) 
            }
          }
          return undefined
        }).filter(percentage => percentage !== undefined)
        percentages.push(...assessmentPercentages)
      })

      const total = percentages.reduce((total, num) => total + num, 0)
      const numGrades = percentages.length

      return numGrades > 0 ? total / numGrades : 0
    },
    convertGradeToPercentage(grade, gradingScheme) {
      const gradeObj = _.find(gradingScheme.grades, { name: grade })
      return gradeObj?.percentage
    },
  },
})
