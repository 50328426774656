import { app, mongo } from "./client"

const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'reports'

export async function getAll() {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { owner_id: app.currentUser.id }
    )
}

export async function getOne(id) {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { _id: id }
    )
}

export async function updateOne(doc) {
  doc.owner_id = app.currentUser.id
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: doc._id },
      { $set: doc },
      { upsert: true },
    )
}

export async function deleteOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .deleteOne({ _id: doc._id })
}