import { defineStore } from 'pinia'
import _ from 'lodash'

import { Expectation } from '@/db'
import { useAuthUserStore } from './authUser'
import { useAssessmentStore } from './assessment'
import { useStudentStore } from './student'

export const useExpectationStore = defineStore('expectation', {
  state: () => {
    return {
      items: [],
      regions: [],
      allTeachables: [],
      selectedItems: [],
      authUserStore: useAuthUserStore(),
    }
  },
  getters: {
    availableRegions(state) {
      return state.regions.filter(region => region.isAvailable)
    },
  },
  actions: {
    async getAll() {
      if (this.regions.length === 0) {
        await this.getRegions()
      }
      const regionId = this.authUserStore.userData.regionId
      
      if (!regionId) {
        return
      }

      const region = _.find(this.regions, { _id: regionId })
      
      var myTeachables = []
      var teachables = this.authUserStore.userData.teachables
      for (var teachable in teachables) {
        // If the user did not select any subjects in a grade.
        if (teachables[teachable].length === 0) {
          continue
        }

        // If the user teaches some subjects in a grade.
        for (var subject of teachables[teachable]) {
          myTeachables.push({
            grade: teachable,
            subject: subject
          })
        }
      }
      
      if (myTeachables.length === 0) {
        return
      }

      await Expectation.getAll(region.name, myTeachables)
        .then((response) => {
          this.items = response
        })
    },
    async getRegions() {
      await Expectation.getRegions()
        .then((response) => {
          this.regions = response
        })
    },
    async getAllTeachables() {
      const regionId = this.authUserStore.userData.regionId
      const region = _.find(this.regions, { _id: regionId })
      await Expectation.getAllTeachables(region.name)
        .then((response) => {
          this.allTeachables = response
        })
    },
    async calcStudentStats(_student, _class) {
      // Get the full student object (_student is from the assessment object).
      const studentStore = useStudentStore()
      var student = _.find(studentStore.items, { _id: _student._id })

      // Calculate the student's stats for all relevant expectations.
      var expectations = await this.getStudentExpectations(student)
      expectations = expectations.filter((item) => {
        return (
          item.numAssessments > 0 
          && item.grade == _class.grade 
          && item.subject == _class.subject
        )
      })

      // Find the student's best and worst expectations for this class.
      expectations = _.orderBy(expectations, ['avgGrade'])
      const expectationBest = _.pick(_.last(expectations), ['_id', 'expectation', 'avgGrade'])
      const expectationWorst = _.pick(_.first(expectations), ['_id', 'expectation', 'avgGrade'])

      // Store results on the student record.
      student.classes = student.classes.map((item) => {
        if (_.isEqual(item._id, _class._id)) {
          item.expectationBest = expectationBest
          item.expectationWorst = expectationWorst
        }
        return item
      })
      await studentStore.updateOne(student)
    },
    async getStudentExpectations(student) {
      // Make sure the student's assessments are loaded.
      const assessmentStore = useAssessmentStore()
      await assessmentStore.getAssessmentsForStudent(student)

      // Only include expectations that apply to this student.
      var expectations = this.items.filter(expectation => {
        for (const _class of student.classes) {
          if (expectation.grade == _class.grade && expectation.subject == _class.subject) {
            return true
          }
        }
        return false
      })
      .map(expectation => {
        var filteredAssessments = this.getAssessmentsForExpectation(
          expectation, assessmentStore.studentAssessments
        )
        // Don't include assessments where the student hasn't received a grade for this
        // expectation.
        filteredAssessments = filteredAssessments.filter(assessment => {
          const _expectationGrade = _.find(assessment.expectationGrades, { _id: expectation._id })
          return _expectationGrade.grade != ''
        })
        expectation.numAssessments = filteredAssessments.length
        expectation.avgGrade = this.getStudentAverageForExpectation(expectation, filteredAssessments)
        return expectation
      })
      return expectations
    },
    async getClassExpectations(_class) {
      // Make sure the class's assessments are loaded.
      const assessmentStore = useAssessmentStore()
      await assessmentStore.getAssessmentsForClass(_class)

      // Only include expectations that apply to this class.
      let expectations = this.items.filter(expectation => {
        return expectation.grade == _class.grade && expectation.subject == _class.subject
      })

      // Calculate stats for each expectation.
      expectations.forEach(expectation => {
        let filteredAssessments = this.getAssessmentsForExpectation(
          expectation, assessmentStore.classAssessments
        )

        // Find the class average for this expectation on each assessment. If, for a particular
        // assessment, the class has not been graded on this expectation, calculate the class's 
        // overall average on the assessment. If no overall average can be calculated, ignore
        // the assessment.
        let total = 0
        let numStudents = 0
        let numAssessments = 0
        filteredAssessments.forEach(assessment => {
          let { _total, _numStudents } = this.getClassAverageForExpectationOnAssignment(assessment, expectation)
          if (_numStudents === 0) {
            _total, _numStudents = this.getClassAverageForAssessment(assessment)
          }
          if (_numStudents > 0) {
            total += _total
            numStudents += _numStudents
            numAssessments += 1
          }
        })
        expectation.numAssessments = numAssessments
        expectation.avgGrade = numStudents > 0 ? total / numStudents : 0
      })
      return expectations
    },
    getClassAverageForExpectationOnAssignment(assessment, expectation) {
      let _total = 0
      let _numStudents = 0
      assessment.students.forEach(student => {
        const expectationGrade = _.find(student.expectationGrades, { _id: expectation._id })

        // Only include the student if they've received a grade on the expectation.
        if (expectationGrade !== undefined && expectationGrade.grade !== '') {
          const grade = _.find(assessment.gradingScheme.grades, { name: expectationGrade.grade })
          _numStudents += 1
          _total += grade.percentage
        }
      })
      return { _total: _total, _numStudents: _numStudents}
    },
    getClassAverageForAssessment(assessment) {
      var _total = 0
      var _numStudents = 0
      assessment.students.forEach((student) => {
        if (student.grade !== '') {
          const grade = _.find(assessment.gradingScheme.grades, { name: student.grade })
          _numStudents += 1
          _total += grade.percentage
        }
      })
      return { _total: _total, _numStudents: _numStudents }
    },
    getAssessmentsForExpectation(expectation, assessments) {
      // Return assessments that include the expectation.
      return assessments.filter(assessment => {
        return assessment.expectations.some(item => _.isEqual(item._id, expectation._id))
      })
    },
    getStudentAverageForExpectation(expectation, assessments) {
      // For each expectation in each assessment, convert the student's grade into a percentage.
      assessments = assessments.map((item) => {
        for (const expectationGrade of item.expectationGrades) {
          if (_.isEqual(expectation._id, expectationGrade._id)) {
            item.grade = expectationGrade.grade?.name
            item.percentage = expectationGrade.grade?.percentage
          }
        }
        return item
      })
      const assessmentsWithGrades = assessments.filter(item => item.percentage !== undefined)
      const expectationTotal = assessmentsWithGrades.reduce((total, assessment) => {
        // The percentage may be undefined if the student hasn't received a grade yet.
        return total + (assessment.percentage || 0)
      }, 0)
      return assessmentsWithGrades.length > 0 ? Math.round(expectationTotal / assessmentsWithGrades.length) : 0
    },
  },
})