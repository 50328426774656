import * as Realm from 'realm-web'
import axios from 'axios'

import { app, mongo } from "./client"

const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'users'
const MONGODB_APP_ID = import.meta.env.VITE_MONGODB_APP_ID

export async function registerUser(email, password) {
  await app.emailPasswordAuth.registerUser({ email, password })
}

export async function confirmUser(token, tokenId) {
  await app.emailPasswordAuth.confirmUser({ token, tokenId })
}

export async function loginUser(email, password) {
  const credentials = Realm.Credentials.emailPassword(email, password)
  const user = await app.logIn(credentials)
  console.assert(user.id === app.currentUser.id)
  return user
}

export async function logoutUser() {
  await app.currentUser.logOut()
}

export async function requestPasswordReset(email) {
  await app.emailPasswordAuth.sendResetPasswordEmail({ email })
}

export async function resetPassword(password, token, tokenId) {
  await app.emailPasswordAuth.resetPassword({ password, token, tokenId })
}

export async function getUserData() {
  var user = await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .findOne(
      { owner_id: app.currentUser.id },
      { projection: { 'referral.promoId': 0 } },
    )
  return user
} 

export async function updateUserData(doc) {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: doc._id },
      { $set: doc }
    )
}

export async function notifyMeAnonymously(doc) {
  await axios.post(
    `https://us-central1.gcp.data.mongodb-api.com/app/${MONGODB_APP_ID}/endpoint/notify`,
    doc
  )
}