import { app, mongo } from "./client"

const DATABASE = import.meta.env.VITE_MONGODB_DB
const COLLECTION = 'students'
const COMMENT_COLLECTION = 'comments'

export async function getAll() {
  return await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .find(
      { owner_id: app.currentUser.id }
    )
}

export async function insertMany(docs) {
  docs = docs.map(item => {
    item.owner_id = app.currentUser.id
    return item
  })
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .insertMany(docs)
}

export async function updateOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: doc._id },
      { $set: doc }
    )
}

export async function deleteOne(doc) {
  await mongo()
    .db(DATABASE)
    .collection(COMMENT_COLLECTION)
    .deleteMany({ 'student._id': doc._id })

  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .deleteOne({ _id: doc._id })
}

export async function addClassToStudents(students, _class) {
  const studentIds = students.map(item => item._id)

  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateMany(
      { _id: { $in: studentIds } },
      { $addToSet: { classes: _class } }
    )
}

export async function removeClassFromStudent(student, _class) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateOne(
      { _id: student._id },
      { $pull: { classes: { _id: _class._id } } }
    )
}

export async function removeClassFromAllStudents(_class) {
  await mongo()
    .db(DATABASE)
    .collection(COLLECTION)
    .updateMany(
      { },
      { $pull: { classes: { _id: _class._id } } }
    )
}