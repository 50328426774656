import { defineStore } from 'pinia'
import * as Realm from 'realm-web'
import _ from 'lodash'

import { Comment } from '@/db'

const TEMP_PROPERTIES = [
  {
    name: 'isSelected',
    default: false,
  },
]
const {
  BSON: { ObjectId },
} = Realm

export const useCommentStore = defineStore('comment', {
  state: () => {
    return {
      items: [],
    }
  },
  actions: {
    async getAll() {
      // Comment.getStudentClasses() returns an array of objects. Each object 
      // represents a class a student is taking. Therefore, there can be 
      // multiple entries per student. Then we merge this array with actual 
      // comment records so we have one list to display to the user.
      var temp = []
      await Comment.getStudentClasses()
        .then(async (response) => {
          this.items = response

          await Comment.getAll()
            .then((response) => {
              temp = response
            })
        }).then(() => {
          // If a comment exists, merge it with student-class item.
          this.items = this.items.map((item) => {
            const doc = this.findDocInArray(temp, item)
            var result = doc !== undefined ? doc : item
            result = this.addTempProperties(result)
            return result
          })
        })
    },
    async updateOne(doc) {
      const cleanDoc = this.getCleanDoc(doc)
      await Comment.updateMany([cleanDoc])
        .then(() => {
          this.replaceDocInArray(doc)
        })
    },
    async updateMany(docs) {
      const cleanDocs = docs.map(item => this.getCleanDoc(item))
      await Comment.updateMany(cleanDocs)
    },
    findDocInArray(arr, doc) {
      const matchedDoc = _.find(arr, {
        'student': doc.student,
        'class': doc.class,
      })
      return matchedDoc
    },
    replaceDocInArray(doc) {
      const idx = _.findIndex(this.items, {
        'student': doc.student,
        'class': doc.class,
      })
      const updatedDoc = {...this.items[idx], ...doc}
      this.items.splice(idx, 1, updatedDoc)
    },
    getCleanDoc(doc) {
      doc = this.generateId(doc)

      // A version of the doc with temp props removed.
      var cleanDoc = _.cloneDeep(doc)
      cleanDoc = this.removeTempProperties(cleanDoc)
      cleanDoc = this.setWriteOperation(cleanDoc)
      return cleanDoc
    },
    addTempProperties(doc) {
      TEMP_PROPERTIES.forEach(item => doc[item.name] = item.default)
      return doc
    },
    removeTempProperties(doc) {
      TEMP_PROPERTIES.forEach(item => delete doc[item.name])
      return doc
    },
    generateId(doc) {
      if (!Object.keys(doc).includes('_id')) {
        doc._id = new ObjectId()
      }
      return doc
    },
    setWriteOperation(doc) {
      if (doc.text == '' && !doc.isLocked) {
        return { deleteOne: { filter: { _id: doc._id } } }
      }
      return {
        updateOne: {
          filter: { _id: doc._id },
          update: { $set: doc },
          upsert: true,
        }
      }
    },
  },
})
